// -----------------------------------------------------------------------------
// Mobile nav toggler
// -----------------------------------------------------------------------------

const navOpener = document.querySelector('.jsMobileNavButton');
const mobileNav = document.querySelector('.jsMobileNav');

navOpener.addEventListener('click', () => {
  mobileNav.classList.toggle('jsShowMenu');
  navOpener.classList.toggle('jsClose');
});

// -----------------------------------------------------------------------------
// AOS animation
// -----------------------------------------------------------------------------

AOS.init({
  duration: 200,
  once: true,
  offset: 0,
  delay: 0,
});
